<template>
    <!--上传组件-->
    <div>
        <el-upload class="upload-demo" :action="imgUrl" :headers="myHeaders" :on-change="uploadVideoChange"
                   :on-preview="handlePreview" :on-remove="handleRemove" :show-file-list="uploadRequire.showFile"
                   :file-list="uploadRequire.imgList" :list-type="uploadRequire.listType"
                   :on-success="handleAvatarSuccess" :on-exceed="exceedFile" :limit="uploadRequire.imgCount"
                   :beforeUpload="beforeAvatarUpload">
            <i slot="default" class="el-icon-plus" v-if="uploadRequire.listType == 'picture-card'"></i>
            <el-button v-else :disabled="PopEntrance == 2 || PopEntrance == 3 " size="small" type="primary">{{uploadRequire.btnName}}</el-button>
            <div style="line-height: 20px" slot="tip" class="el-upload__tip" v-if="uploadRequire.showTips">
                <span v-if="uploadRequire.imgNum != 0">文件大小 ≤ {{uploadRequire.imgNum}}M，</span>
                <span v-if="uploadRequire.fileTypeMark == 1 && uploadRequire.imgSize != 0"><span v-if="!uploadRequire.pictureSize">建议</span>文件尺寸大小：{{uploadRequire.imgSize}}px，</span>
                <span>支持格式：{{`${uploadRequire.fileType.join('、')}`}}</span>
            </div>
        </el-upload>
        <!--预览-->
        <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
            <img width="100%" :src="dialogImageUrl">
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "imgUpload",
        props: {
            imgAskObj: Object,
            PopEntrance:Number,
        },
        data() {
            return {
                imgUrl: this.$uploadImgUrl() + 'zyd-common/file/upload?uploadPath=' + this.imgAskObj.uploadPath,
                myHeaders: {
                    Authorization: sessionStorage.getItem('token'),
                    satoken: sessionStorage.getItem('token'),
                },
                uploadRequire: { //上传文件要求
                    imgCount: 0, // 上传文件数量 0表示不限制文件的数量
                    imgSize: '200*200', // 图片尺寸
                    listType: 'picture', // 缩略图类型
                    imgList: [], // 已上传的文件
                    imgNum: 2, // 上传文件兆数 0表示不限兆数
                    showFile: true, // 是否显示已上传文件列表
                    showTips: true, // 是否显示上传提示
                    btnName: '上传图片', // 按钮名称
                    fileTypeMark: 1, // 上传文件类型标识 1：图片；2：视频
                    fileType: ['jpg', 'png', 'gif', 'jpeg', 'bmp'], // 上传文件类型
                    pictureSize: false, //是否开启验证图片尺寸
                },
                imgArr: [],
                dialogImageUrl: '',
                dialogVisible: false,
            }
        },
        watch: {
            imgAskObj: {
                handler() {
                    this.getVal();
                },
                deep: true
            }
        },
        created() {
            this.getVal();
        },
        methods: {
            getVal() {
                Object.keys(this.imgAskObj).map((key1) => {
                    Object.keys(this.uploadRequire).map((key2) => {
                        if (key1 == key2) {
                            this.uploadRequire[key2] = this.imgAskObj[key1]
                        }
                    })
                })
            },
            // 获取视频时长
            uploadVideoChange(file, fileList) {
                if (file.status !== 'ready') return
                const getVideoInfo = new Promise((resolve) => {
                    const videoElement = document.createElement("video");
                    videoElement.src = URL.createObjectURL(file.raw);
                    videoElement.addEventListener("loadedmetadata", function () {
                        resolve({
                            duration: videoElement.duration,
                            height: videoElement.videoHeight,
                            width: videoElement.videoWidth
                        });
                    });
                });
                getVideoInfo.then((videoInfo) => {
                    this.$emit('getVideoInfo', videoInfo.duration);
                })
            },
            handleRemove(file, imgList) {
                this.imgArr = [];
                imgList.map(item => {
                    if(item.response) {
                        this.imgArr.push({
                            imgName: item.name,
                            imgUrl: item.response.data
                        })
                    } else {
                        this.imgArr.push({
                            imgName: item.name || '',
                            imgUrl: item.url
                        })
                    }
                })
                /*for (let i = 0; i < imgList.length; i++) {
                    this.imgArr.push(imgList[i].response.data)
                }*/
                this.$emit('getImgList', this.imgArr);
            },
            handlePreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            // 文件上传成功时的钩子
            handleAvatarSuccess(response, file, imgList) {
                if (response.status == true) {
                    this.imgArr = [];
                    imgList.map(item => {
                        if(item.response) {
                            this.imgArr.push({
                                imgName: item.name,
                                imgUrl: item.response.data
                            })
                        } else {
                            this.imgArr.push({
                                imgName: item.name || '',
                                imgUrl: item.url
                            })
                        }
                    })
                }
                this.$emit('getImgList', this.imgArr);
                /*if (response.status == true) {
                    this.imgArr = [];
                    for (let i = 0; i < imgList.length; i++) {
                        let image = {
                            imgName: imgList[i].name,
                            imgUrl: imgList[i].response.data
                            // imgUrl:imgList[i].url
                        }
                        this.imgArr.push(image)
                    }
                    this.$emit('getImgList', this.imgArr);
                }*/
            },

            // 文件超出个数限制时的钩子
            exceedFile(files, imgList) {
                if (this.uploadRequire.imgCount > 0) {
                    this.$message.error("只能选择" + this.uploadRequire.imgCount + "个文件");
                }
            },

            // 限制文件大小跟格式
            beforeAvatarUpload(file) {
                let that = this;
                let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
                let testType = Boolean(that.uploadRequire.fileType.find(item => item == testmsg) == testmsg)
                if (!testType) {
                    that.$message.error(`上传文件只能是${that.uploadRequire.fileType.join('、')}格式!`);
                    return false
                }
                const isLt2M = file.size / 1024 / 1024 <= this.uploadRequire.imgNum
                if (!isLt2M && this.uploadRequire.imgNum != 0) {
                    this.$message.error("上传文件大小不能超过 " + this.uploadRequire.imgNum + "MB!");
                    return false
                }

                //限制图片分辨率大小
                if (this.uploadRequire.pictureSize) {
                    let width = this.uploadRequire.imgSize.split('*')[0];
                    let height = this.uploadRequire.imgSize.split('*')[1];
                    let _URL = window.URL || window.webkitURL;
                    let valid = '';
                    let img = new Image();
                    img.src = _URL.createObjectURL(file);
                    let gsha = new Promise(function (resolve, reject) {
                        img.onload = () => {
                            valid = img.width == width && img.height == height;
                            valid ? resolve() : reject()
                        }
                    }).then(() => {
                        return file
                    }).catch(() => {
                        this.$message.error("上传图片分辨率必须为 " + this.uploadRequire.imgSize + "px!");
                        return Promise.reject(false)
                    })
                    return  gsha
                }
            }
            ,
        }
    }
</script>

<style scoped>

</style>
